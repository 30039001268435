@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./assets/fonts/Tajawal-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Allow vertical scrolling */
  font-family: "Tajawal", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: none; /* Prevent scroll bounce effects */

  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
